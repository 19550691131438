
import Main from './pages/Main'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <header className="OliverErdmann">
      </header>
      <Main/>
    </div>
  );
}

export default App;
